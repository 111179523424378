/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import "./layout.css"



const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          
        }
      }
    }
  `)

  return (

<div>


    <div className="outerdiv">
      <Header siteTitle={data.site.siteMetadata.title} siteDescription={data.site.siteMetadata.description}  />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 800,
          padding: `0 1.0875rem 1.45rem`,
        }}>


        <main >{children}</main>
      </div>

        <footer style={{
            backgroundColor: `rgba(87, 33, 33,1)`,
            padding: `1.0875rem 1.45rem`,
            color:`white`,
            display:`block`,
            margin:`1.45rem 0 0`,
            textAlign: `center`

        }}>
            © {new Date().getFullYear()}, Marie-Anne Melis&nbsp;
            <a style={{
                color:`white`,
            }}target="_blank" rel="noopener noreferrer" href="https://emma-design.nl">emma-design.nl</a>
        </footer>
    </div>
</div>

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
