import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Menu from "../components/menu"


const Header = ({ siteTitle , siteDescription}) => (

  <header
    style={{
      background: `#572121`,
      marginBottom: `1.45rem`,
    }}
  >

    <div
      style={{
        margin: `0 auto`,
        maxWidth: 800,
        padding: `1.45rem 1.0875rem`,
      }}
    >


      <h1 style={{ margin: 0 }}>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}

        </Link>

      </h1>
        <h4 style={{
            color: `rgba(255,255,255,0.5)`,
            textDecoration: `none`,
            fontWeight: `400`
        }}>{siteDescription}</h4>
        <Menu/>
    </div>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
    siteDescription:PropTypes.string
}

Header.defaultProps = {
    siteTitle: ``,
    siteDescription: ``,
}

export default Header
