import { Link } from "gatsby"
import React from "react"


const Menu = () => {
    const menuItemStyle = {
        color:'white',
        textDecoration:'none',
        padding:'1rem 1rem 1rem 0',
        fontWeight:'400'
    };

    return (
        <nav>
            <Link style={menuItemStyle} activeStyle={{ borderBottom: "2px solid rgba(255,255,255,0.7)" }} to="/blog">Blog</Link>
            <Link style={menuItemStyle} activeStyle={{ borderBottom: "2px solid rgba(255,255,255,0.7)" }} to="/projects">Projects</Link>
            <Link style={menuItemStyle} activeStyle={{ borderBottom: "2px solid rgba(255,255,255,0.7)" }} to="/about-me">About</Link>
        </nav>
    )
}

export default Menu;